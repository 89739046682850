import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import { CONTAINER_TYPES } from "../../infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import DfContent from "@/vue/domain/content/df-content";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfProduct from "@/vue/domain/product/df-product";
import DfPromotion from "@/vue/domain/promotion/df-promotion";
import { Carousel, Slide } from "vue-carousel-variable-width";
const DfCardProductComponent = () => Utils.externalComponent2("df-card-product");

@Component({
  components: {
    DfCardProduct: DfCardProductComponent,
    Carousel,
    Slide,
  }
})
export default class DfSectionSpecialComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  @Prop({ type: Object, default: null })
  promotion: DfPromotion;
  @Prop({ type: Object, default: null })
  special: DfContent;

  get specialBackgroundUrl(): string {
    return Utils.getPropertyImageUrl(this.special, Utils.PROPERTY_BACKGROUND_IMAGE, null);
  }

  get specialBackgroundColor(): string {
    return Utils.getPropertyValue(this.special, Utils.PROPERTY_BACKGROUND_COLOR, "STRING");
  }

  get cachedProducts(): Array<DfProduct> {
    return this.$store.getters.cachedProducts;
  }

  get productsSpecial(): Array<DfProduct> {
    const productsIdentifier: Array<string> = this.special && this.special.products ? this.special.products : [];
    return productsIdentifier.map((productIdentifier: string) => this.cachedProducts.find((product: DfProduct) => product.uniqueId === productIdentifier)).filter((product: DfProduct) => product);
  }

  get specialPreviewStyle(): any {
    const style: any = {};
    const specialPreview = this.specialBackgroundUrl;
    const specialBackgroundColor = this.specialBackgroundColor;

    if (specialPreview) {
      style.backgroundImage = `url('${specialPreview}')`;
    }

    if (specialBackgroundColor) {
      style.backgroundColor = `${specialBackgroundColor}`;
    }

    return style;
  }

  get imageContent(): string {
    return Utils.getPropertyImageUrl(this.special, Utils.PROPERTY_CONTENT_IMAGE, null) || Utils.getPropertyImageUrl(this.special, Utils.PROPERTY_LOGO, null);
  }

  get htmlContent(): string {
    return Utils.getPropertyValue(this.special, Utils.PROPERTY_CONTENT_HTML, "TEXT");
  }

  get videoContent(): string {
    return Utils.getPropertyValue(this.special, Utils.PROPERTY_CONTENT_VIDEO, "STRING");
  }

  get videoCustomContent(): string {    
    return Utils.getPropertyImageUrl(this.special, Utils.PROPERTY_CONTENT_VIDEO_CUSTOM, null);
  }

  @Watch("special", { immediate: true })
  private changeVisibility() {
    if (this.special) {
      this.loadProductsList();
    }
  }

  private loadProductsList() {
    const productsUniqueId: Array<string> = this.special && this.special.products ? this.special.products : [];

    if (productsUniqueId.length > 0 && productsUniqueId.length !== this.productsSpecial.length) {
      this.$root.$emit("loadCachedProducts", productsUniqueId, 10);
    }
  }
}
